@import url(https://api.fontshare.com/v2/css?f[]=clash-display@400,700,500,600&display=swap);


body {
    margin: 0;
    padding: 0;
    font-family: 'Clash Display', sans-serif;
    font-display: swap;
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
    background: white;
    color: black;

}

h1,
h2 {
    text-transform: uppercase;
}


.loader {
    transform: scale(1);
    animation: pulse 2s infinite;
    width: 4em;
}

details>summary {
    list-style: none;
}

details>summary::-webkit-details-marker {
    display: none;
}

@keyframes pulse {
    0% {
        transform: scale(0.55);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.95);
    }
}


input[type="datetime-local"] {
    display: block;

    /* Solution 1 */
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.2em;
}

input[type="file"] {
    opacity: 0;
    position: "relative"
}


@tailwind base;
@tailwind components;
@tailwind utilities;